<template>
    <main-container icon="mdi-contacts" title="Contatos do site">

        <EmcTableItems
            :headers="headers" 
            v-bind:params.sync="params"
            v-bind:collections.sync="collections"
            v-bind:meta.sync="meta"
            v-bind:itemDelete.sync="itemDelete" 
            v-bind:itemEdit.sync="model"
            :loading="loading"
        />

        <!-- Modal usado para mensagem e ação de exclusão -->
        <emc-alert-modal :text="text" v-bind:show.sync="show">
            <template v-slot:actions>
                <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
            </template>
        </emc-alert-modal>
        
        <!-- Modal para editar -->
        <emc-modal-form :title="titleForm" icon="mdi-contacts" v-bind:show.sync="showForm">
            <template v-slot:form>
                <v-container>
                    <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field dense :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome*" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field dense :error="errors.length > 0" :error-messages="errors.email" v-model="model.email" label="Email*" required></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="12" sm="4">
                        <v-text-field dense :error="errors.length > 0" :error-messages="errors.phone" v-model="model.phone" label="Telefone*" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field dense :error="errors.length > 0" :error-messages="errors.company" v-model="model.company" label="Escola"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field disabled dense :error="errors.length > 0" :error-messages="errors.job_title" v-model="model.job_title" label="Cargo"></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="12" sm="8">
                        <v-text-field disabled dense :error="errors.length > 0" :error-messages="errors.subject" v-model="model.subject" label="Assunto*" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                            <SysSelectStatusContact
                                :model.sync="model.status_contact_id"
                                :error="errors.length > 0"  
                                :errors="errors.status_contact_id"
                                label="Status*"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                label="Descrição"
                                v-model="model.description"
                                filled
                                disabled
                                ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                label="Comentário interno:"
                                v-model="model.comments"
                                ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*Campos obrigatórios</small> <br />
                <small><v-icon small>mdi-clock-outline</v-icon> criado {{ model.created_at }}</small>
            </template>

            <template v-slot:actions>
                <v-btn color="primary" @click.prevent.stop="save()" :loading="updating" :disabled="updating">{{ (model.id) ? 'Atualizar' : 'Cadastrar'}} <v-icon right dark>mdi-content-save</v-icon></v-btn>
            </template>

        </emc-modal-form>
        
        <!-- Alert para mensagens -->
        <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
    </main-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'contact',
    data() {
        return {
            model: {},
            collections: [],
            message: '',
            error: false,
            errors: {},
            updating: false,
            deleting: false,
            loading: false,
            showForm: false,
            selected: null,
            params: {},
            itemDelete: {},
            meta: {},
            text: '',
            show: false,
            titleForm: '',
            headers: [
                { text: 'Nome', value: 'name', sortable: false},
                { text: 'Email', value: 'email', sortable: false},
                { text: 'Cadastrado em', value: 'created_at', sortable: false },
                { text: 'Status', value: 'status.name', sortable: false},
                { text: 'Ações', value: 'actions', sortable: false}
            ]
        }
    },
    methods: {
        ...mapActions('contact', ['ActionFindContacts', 'ActionDestroyContacts', 'ActionUpdateContacts']),
        deleteItem()
        {
            this.deleting = true
            this.message = ''
            
            this.ActionDestroyContacts({ id: this.itemDelete.id} )
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false
                })
        },
        getData() {
            
            this.error = false;
            this.message = '';
            this.loading = true;
            this.params.with = 'status';
            this.params.column_order = 'status_contact_id';
            
            this.ActionFindContacts(this.params)
                .then((res) => {
                    this.collections = res.data;
                    this.meta = res.meta;
                })
                .finally(() => {
                    this.loading = false
                })
        },

        save()
        {
            this.updating = true
            this.message = ''

            if(this.model.id) {
                this.ActionUpdateContacts(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.error = false;
                    this.show = false;
                    this.showForm = false
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = true
                    this.errors = error.errors
                })
                .finally(() => {
                    this.updating = false
                })
            } 
            
        },
    },
    watch: {
        
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        }, //Função para tratar quando um item for exibido para deletar
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir " + item_new.name + "?"
            } else {
                this.show = false
                this.text = ''
            }
        }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
        
        show(show_new, show_old) {
            
            if(show_new != show_old && !show_new) {
                this.itemDelete = {};
            }
        },

        model(item_new, item_old){
            
            if(item_new !== item_old && (this.model && this.model.id))
            {
                this.titleForm = 'Editar contato do site'
                this.showForm = true
                this.errors = {}

            } else if(Object.keys(this.model).length === 0){
                
                this.titleForm = 'Cadastrar usuário';
                this.showForm = false;
                this.errors = {};
            }           
        },

        showForm(show_new, show_old) {
            
            if(show_new){
                
                if(!this.model || !this.model.id){
                    this.model = { 
                        campuses: [],
                        active: 1
                     };
                   
                }

                this.openAll = false;
            }else{
                this.model = {};
            }
        },
    },
}
</script>